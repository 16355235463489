import React, { FunctionComponent } from 'react';
import { DForecast } from '../../../types/accuweather/daily-forecast';

export const Summary: FunctionComponent<{forecast5dayInfo: DForecast}> = ({forecast5dayInfo}): JSX.Element => {
  return (
    <div className="summary">
      <hr/>
      <div className="headline">{forecast5dayInfo.DailyForecasts[0].Day.LongPhrase}</div>
      <hr/>
    </div>
  )
};
