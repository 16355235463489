import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { autocomplete } from '../../services/accuweather';

export const Search: FunctionComponent<{
  setResults: Dispatch<any>
}> = ({
  setResults
}): JSX.Element => {

  const [searchTerm, setSearchTerm] = useState<string>('');

  const setTerm = async (event: any): Promise<void> => {
    setSearchTerm(event.target.value);
  }

  const parseResult = (result: any) => {
    const { AdministrativeArea, Key, LocalizedName, Country } = result;
    return { Key, State: AdministrativeArea.ID, LocalizedName, Country: Country.LocalizedName };
  }

  const autoCompleteCities = async (): Promise<void> => {
    const results = await autocomplete(searchTerm);

    setResults(results?.map((result: any) => parseResult(result)))
  };

  useEffect(() => {
    autoCompleteCities();
  }, [searchTerm, setResults]);

  return (
    <div>
      <div>
        <label htmlFor="name">Search City Name</label>
      </div>
      <div>
        <input type="text" name="search" onChange={setTerm} value={searchTerm}/>
      </div>
    </div>
  );
};
