import axios from 'axios';
import { CurrentConditions } from '../types/accuweather/current-conditions';
import { DForecast } from '../types/accuweather/daily-forecast';
import { HourlyForecast } from '../types/accuweather/hourly-forecast';
import { LocationInfo } from '../types/accuweather/location-info';

const local = !!process.env.REACT_APP_LOCAL;

const base = local ? 'http://localhost:8080/v1/proxy' : ' https://oxkuxfd50a.execute-api.us-east-1.amazonaws.com/v1/proxy';

const accuUrlMap = {
  currentconditions: (locationKey: string) => `${base}/currentconditions/v1/${locationKey}?details=true`,
  forecast5day: (locationKey: string) => `${base}/forecasts/v1/daily/5day/${locationKey}?details=true`,
  forecast12hour: (locationKey: string) => `${base}/forecasts/v1/hourly/12hour/${locationKey}?details=true`,
  autocomplete: (q: string) => `${base}/locations/v1/cities/autocomplete?q=${q}`,
  locationSearch: (locationKey: string) => `${base}/locations/v1/${locationKey}`
}


export const currentconditions = (locationKey: string) => {
  return axios.get<CurrentConditions>(accuUrlMap['currentconditions'](locationKey)).then(res => res?.data);
}

export const forecast5day = (locationKey: string) => {
  return axios.get<DForecast>(accuUrlMap['forecast5day'](locationKey)).then(res => res?.data);
}

export const forecast12hour = (locationKey: string) => {
  return axios.get<HourlyForecast[]>(accuUrlMap['forecast12hour'](locationKey)).then(res => res?.data);
}

export const autocomplete = (q: string) => {
  return axios.get(accuUrlMap['autocomplete'](q)).then(res => res?.data);
}

export const getLocationInfo = (locationKey: string) => {
  return axios.get<LocationInfo>(accuUrlMap['locationSearch'](locationKey)).then(res => res?.data);
}
