import { FunctionComponent, useEffect, useState } from 'react';
import { currentconditions, forecast12hour, forecast5day, getLocationInfo } from '../../../services/accuweather';
import { CurrentConditions } from '../../../types/accuweather/current-conditions';
import { DForecast } from '../../../types/accuweather/daily-forecast';
import { HourlyForecast } from '../../../types/accuweather/hourly-forecast';
import { getDate } from '../../../services/utils';
import { Secondary } from '../Secondary';
import { Header } from '../Header';
import { LocationInfo } from '../../../types/accuweather/location-info';
import { Precip } from '../Precip';
import { Summary } from '../Summary';
import { AirAndPollenGraph, HourlyRating, RainGraph } from '../../graphs/Rain';
import { HiLo } from '../HiLo';
import { DailyRating } from '../../../rating/DailyRating';

export const Conditions: FunctionComponent<{
  locationKey: string
}> = ({locationKey}): JSX.Element => {

  const [currentInfo, setCurrentInfo] = useState<CurrentConditions>();
  const [forecast5dayInfo, setForecast5day] = useState<DForecast>();
  const [forecast12hourInfo, setForecast12hour] = useState<HourlyForecast[]>();
  const [locationInfo, setLocationInfo] = useState<LocationInfo>();

  const [showDailyRating, setShowDailyRating] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const getCurrent = async (locationKey: string) => {
    try {
      setCurrentInfo(await currentconditions(locationKey));
      setForecast5day(await forecast5day(locationKey));
      setForecast12hour(await forecast12hour(locationKey));
      setLocationInfo(await getLocationInfo(locationKey));
    } catch (e) {
      setError(true);
    }
  }

  useEffect(() => {
    getCurrent(locationKey);
  }, [locationKey])

  const loaded = currentInfo && forecast5dayInfo && locationInfo && forecast12hourInfo && !error;

  return (
    <>
      {!loaded && (
        <div className="loading">Loading...</div>
      )}
      {(loaded) && (
        <>
          <div>
            <div className="date">{getDate(currentInfo[0].LocalObservationDateTime)}</div>
            <div className="city">{locationInfo.EnglishName}</div>
            <Header currentInfo={currentInfo} setShowDailyRating={setShowDailyRating} />
            <HiLo forecast5dayInfo={forecast5dayInfo}/>
            <Summary forecast5dayInfo={forecast5dayInfo} />
            <Secondary currentInfo={currentInfo} />
            <Precip currentInfo={currentInfo} forecast={forecast5dayInfo}/>
            <RainGraph forecast={forecast12hourInfo}/>
            <AirAndPollenGraph forecast5dayInfo={forecast5dayInfo}/>
            <HourlyRating hourlyForecast={forecast12hourInfo}/>
            {/*forecast12hourInfo: {JSON.stringify(forecast12hourInfo)}*/}
          </div>
          {showDailyRating && (
            <DailyRating
              setShowDailyRating={setShowDailyRating}
              currentInfo={currentInfo}
            />
          )}
        </>
      )}
      {error && (
        <div>
          Error
        </div>
      )}
    </>
  );
}
