import React, { Dispatch, SetStateAction } from 'react';

export const SearchResults = (props: {
  results: any[] | undefined,
  setKey: Dispatch<SetStateAction<string | undefined>>
}): JSX.Element => {

  const {results, setKey} = props;

  return (
    <div>
      {results?.map(result => (
        <ul onClick={() => setKey(result.Key)} key={result.Key}>
          <li>
            {result.LocalizedName + ' ' + result.State} {result.Country}
          </li>
        </ul>
      ))}
    </div>
  );
};
