import { Card } from '../../layout/Card';
import { FunctionComponent } from 'react';
import { DForecast } from '../../../types/accuweather/daily-forecast';
import { Box } from '../../layout/Box';
import { getTime } from '../../../services/utils';

export const HiLo: FunctionComponent<{forecast5dayInfo: DForecast}> = ({forecast5dayInfo}): JSX.Element => {

  const { DailyForecasts: [{Temperature: {Maximum, Minimum}, Sun: {Rise, Set}}] } = forecast5dayInfo;

  return (
    <Card>
      <Box classList={'left'}>
        <>
          <p>Hi {Maximum.Value} ° {Maximum.Unit}</p>
          <p>Lo {Minimum.Value} ° {Minimum.Unit}</p>
        </>
      </Box>
      <Box classList={'left'}>
        <>
          <p>Sunrise: {getTime(Rise)}</p>
          <p>Sunset: {getTime(Set)}</p>
        </>
      </Box>
    </Card>
  )
};
