import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { clearData, getData, storeData } from '../services/localStorage';
import './Main.css';
import { Search } from './accuweather/Search';
import { SearchResults } from './accuweather/SearchResults';
import { Conditions } from './accuweather/Conditions';

export const MainAccu: FunctionComponent<{cityKey?: string; setCityKey: Dispatch<SetStateAction<string | undefined>>}> = ({cityKey, setCityKey}): JSX.Element => {

  const [results, setResults] = useState<any[]>();

  useEffect(() => {
    const cityKey = getData('cityKey');
    if (!!cityKey) {
      setCityKey(cityKey);
    }
  }, [setCityKey]);

  useEffect(() => {
    if (cityKey) {
      storeData('cityKey', cityKey);
    } else {
      clearData('cityKey');
    }
  }, [cityKey])

  const isMock = !!process.env.REACT_APP_MOCK;

  return (
    <>
      <div>
        <div className="main">
          {!cityKey && (
            <>
              <div className="search-container">
                <Search setResults={setResults}/>
              </div>
              <div className="search-results">
                <SearchResults results={results} setKey={setCityKey}/>
              </div>
            </>
          )}
          {cityKey && (
            <>
              <Conditions locationKey={cityKey}/>
            </>
          )}

          {(isMock && !cityKey) && (
            <button onClick={() => setCityKey('mock')}>MOCK</button>
          )}
        </div>
      </div>
    </>
  )
}
