import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { CurrentConditions } from '../../../types/accuweather/current-conditions';
import { Card } from '../../layout/Card';
import { Box } from '../../layout/Box';
import { getTemp } from '../../../services/utils';
import { Temp } from '../../layout/Temp';
import { Rating } from '../../layout/Rating';
import { ratingForCurrent } from '../../../services/rating';

type HeaderProps = {
  setShowDailyRating: Dispatch<SetStateAction<boolean>>;
  currentInfo: CurrentConditions;
};

export const Header: FunctionComponent<HeaderProps> = (
  {currentInfo, setShowDailyRating}
): JSX.Element => {
  const rating = ratingForCurrent(currentInfo[0]).rating;

  return (
    <Card>
      <Box>
        <Temp temp={getTemp(currentInfo[0], 'Temperature', 'Imperial')} />
        <p>{currentInfo[0].WeatherText}</p>
        <h2>Feels like {getTemp(currentInfo[0], 'RealFeelTemperature', 'Imperial')}</h2>
      </Box>
      <Box classList={'center'} onClick={() => setShowDailyRating(true)} >
        <Rating rating={rating}/>
      </Box>
    </Card>
  );
};
