import { FunctionComponent } from 'react';
import { Card } from '../../layout/Card';
import { CurrentConditions } from '../../../types/accuweather/current-conditions';
import { Box } from '../../layout/Box';
import { DForecast } from '../../../types/accuweather/daily-forecast';

export const Precip: FunctionComponent<{currentInfo: CurrentConditions, forecast: DForecast}> = ({currentInfo, forecast}): JSX.Element => {
  return (
    <>
      <Card classList="center" label="Precipitation">
        <Box classList="third">
          <p>Past 12 hours</p>
          <p>{currentInfo[0].PrecipitationSummary.Past12Hours.Imperial.Value} in</p>
        </Box>
        <Box classList="third">
          <p>Today</p>
          <p>{forecast.DailyForecasts[0].Day.PrecipitationProbability} %</p>
        </Box>
      </Card>
    </>
  )
}
