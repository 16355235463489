import { CurrentCondition } from '../types/accuweather/current-conditions';
import { HourlyForecast } from '../types/accuweather/hourly-forecast';

interface IRatingInput {
  temperature: number,
  windSpeed: number,
  gusts: number,
  humidity: number
}

export const ratingForCurrent = (weatherData: CurrentCondition): CurrentRating => {
   const { Temperature, Wind, WindGust, RelativeHumidity } = weatherData;

    const temperature = Temperature.Imperial.Value;
    const windSpeed = Wind.Speed.Imperial.Value;
    const gusts = WindGust.Speed.Imperial.Value;
    const humidity = RelativeHumidity;

    return currentWeatherRating({
      temperature,
      windSpeed,
      gusts,
      humidity,
    });
}

export const ratingForHour = (weatherData: HourlyForecast): CurrentRating => {
  const { Temperature, Wind, WindGust, RelativeHumidity } = weatherData;

  const temperature = Temperature.Value;
  const windSpeed = Wind.Speed.Value;
  const gusts = WindGust.Speed.Value;
  const humidity = RelativeHumidity;

  return currentWeatherRating({
    temperature,
    windSpeed,
    gusts,
    humidity,
  });
}

type CurrentRating = {
  rating: number;
  temperatureRating?: number;
  windSpeedRating?: number;
  gustsRating?: number;
  humidityRating?: number;
}

function currentWeatherRating(ratingInput: IRatingInput): CurrentRating {
  let rating: number;

  const { temperature, windSpeed, gusts, humidity } = ratingInput;
  let temperatureRating = 0
  let windSpeedRating = 0
  let gustsRating = 0
  let humidityRating = 0

  // Temperature rating
  if (temperature >= 60 && temperature <= 90) {
    temperatureRating += 2.5;
  } else if (temperature < 60) {
    temperatureRating += 2.5 * (temperature / 60);
  } else {
    temperatureRating += 2.5 * ((90 - temperature) / 30);
  }

  temperatureRating = temperatureRating > 0 ? temperatureRating : 0;

  // Wind speed rating
  if (windSpeed <= 10) {
    windSpeedRating += 2.5;
  } else {
    windSpeedRating += 2.5 * ((10 - windSpeed) / 10);
  }

  windSpeedRating = windSpeedRating > 0 ? windSpeedRating : 0;

  // Gusts rating
  if (gusts <= 20) {
    gustsRating += 2.5;
  } else {
    gustsRating += 2.5 * ((20 - gusts) / 20);
  }

  gustsRating = gustsRating > 0 ? gustsRating : 0;

  // Humidity rating
  if (humidity <= 80) {
    humidityRating += 2.5;
  } else {
    humidityRating += 2.5 * ((80 - humidity) / 20);
  }

  humidityRating = humidityRating > 0 ? humidityRating : 0;

  rating = temperatureRating + windSpeedRating + gustsRating + humidityRating
  const roundRating = Math.round(10 * rating) / 10;

  return { rating: roundRating, temperatureRating, windSpeedRating, gustsRating, humidityRating };
}

/**
 * google bard work
 */


function normalizeWeatherScore(weatherIcon: number): number {
  switch (weatherIcon) {
    case 1: // Sunny
    case 2: // Mostly sunny
      return 100;
    default: // Cloudy or rainy
      return 0;
  }
}

export const calculateMotorcycleRating = (currentConditions: CurrentCondition): CurrentRating => {
  // Normalize each of the factors.
  const weatherScore = normalizeWeatherScore(currentConditions.WeatherIcon);
  const temperatureScore = normalizeTemperatureScore(currentConditions.Temperature.Imperial.Value);
  const humidityScore = normalizeHumidityScore(currentConditions.RelativeHumidity);
  const windScore = normalizeWindScore(currentConditions.Wind.Speed.Imperial.Value);

  console.log({weatherScore,
    temperatureScore,
    humidityScore,
    windScore})

  // Calculate the overall rating.
  const rating = (weatherScore + temperatureScore + humidityScore + windScore) / 4;
  console.log({rating})
  return { rating };
}

function normalizeTemperatureScore(temperature: number): number {
  if (temperature < 40 || temperature > 95) {
    return 0;
  } else if (temperature >= 60 && temperature <= 85) {
    return 100;
  } else {
    return 50;
  }
}

function normalizeHumidityScore(humidity: number): number {
  if (humidity < 70) {
    return 100;
  } else if (humidity >= 70 && humidity <= 80) {
    return 50;
  } else {
    return 0;
  }
}

function normalizeWindScore(windSpeed: number): number {
  if (windSpeed < 15) {
    return 100;
  } else if (windSpeed >= 15 && windSpeed <= 25) {
    return 50;
  } else {
    return 0;
  }
}
