import React, { FunctionComponent, ReactNode } from 'react';

type CardProps = {
  children: ReactNode,
  classList?: string
  label?: string;
}

export const Card: FunctionComponent<CardProps> = ({children, classList, label}): JSX.Element => {
  const classes = classList ? 'card ' + classList : 'card';
  return (
    <>
      {label && <div className="card-label">{label}</div>}
      <div className={classes}>{children}</div>
    </>
  )
};
