import { Dispatch, FC, SetStateAction } from 'react';
import './index.css';
import { CurrentConditions } from '../../types/accuweather/current-conditions';
import { ratingForCurrent } from '../../services/rating';

type DailyRatingProps = {
  setShowDailyRating: Dispatch<SetStateAction<boolean>>;
  currentInfo: CurrentConditions;
}

export const DailyRating: FC<DailyRatingProps> = ({
  setShowDailyRating,
  currentInfo
}) => {
  const ratings = ratingForCurrent(currentInfo[0]);
  return (
    <div className={'fullscreen'}>
      <div onClick={() => setShowDailyRating(false)}>X</div>
      <div>rating: {ratings.rating}</div>
      <div>temperatureRating: {ratings.temperatureRating}</div>
      <div>humidityRating: {ratings.humidityRating}</div>
      <div>windSpeedRating: {ratings.windSpeedRating}</div>
      <div>gustsRating: {ratings.gustsRating}</div>
    </div>
  );
};
