import React, { useState } from 'react';
import './Main.css';
import { MainAccu } from './MainAccu';

export function Main(): JSX.Element {

  const [cityKey, setCityKey] = useState<string>();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const clearKey = (): void => {
    setCityKey(undefined);
  };

  const classes = showMenu ? 'sidenav sidenav-shown' : 'sidenav';

  const clear = () => {
    setShowMenu(!showMenu)
    clearKey();
  }

  return (
    <>
      <div className={classes}>
        <ul>
          <li onClick={() => setShowMenu(!showMenu)}>X</li>
          <li onClick={clear}>Search</li>
        </ul>
      </div>
      <MainAccu cityKey={cityKey} setCityKey={setCityKey}/>
      { !showMenu && (<div className="hamburger" onClick={() => setShowMenu(!showMenu)}>?</div>)}
    </>
  ) as JSX.Element
}
