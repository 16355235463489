export const storeData = (key: string, value: any): void => {
  if (typeof value === 'object')  {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const getData = (key: string): any => {
  return localStorage.getItem(key);
};

export const clearData = (key: string): void => {
  localStorage.removeItem(key);
};
