import { FunctionComponent } from 'react';
import { CurrentConditions } from '../../../types/accuweather/current-conditions';
import { Box } from '../../layout/Box';
import { Card } from '../../layout/Card';

export const Secondary: FunctionComponent<{currentInfo: CurrentConditions}> = ({currentInfo}) => {

  const {Wind, WindGust: {Speed: gustSpeed}, RelativeHumidity} = currentInfo[0];

  return (
    <Card classList={"center"}>
      <Box classList={"quarter"}>
        <p>Wind</p>
        <p>{Wind.Speed.Imperial.Value + ' ' + Wind.Speed.Imperial.Unit + ' ' + Wind.Direction?.English}</p>
      </Box>
      <Box classList={"quarter"}>
        <p>Gusts</p>
        <p>{gustSpeed.Imperial.Value + ' ' + gustSpeed.Imperial.Unit }</p>
      </Box>
      <Box classList={"quarter"}>
        <p>Humidity</p>
        <p>{RelativeHumidity}</p>
      </Box>
    </Card>
  )
}
