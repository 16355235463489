import React, { FunctionComponent, ReactNode } from 'react';

type BoxProps = {
  children: ReactNode,
  classList?: string,
  onClick?: any;
};

export const Box: FunctionComponent<BoxProps> = (
  {children, classList, onClick}
): JSX.Element => {
  return (
    <div className={'box ' + classList} onClick={onClick}>{children}</div>
  )
};
