import React, { FunctionComponent } from 'react';
import { HourlyForecast } from '../../../types/accuweather/hourly-forecast';
import { format } from 'date-fns';
import { Card } from '../../layout/Card';
import { DForecast } from '../../../types/accuweather/daily-forecast';
import { ratingForHour } from '../../../services/rating';

// 6PM, 10PM
const formatTime = (date: string) => format(new Date(date), 'ha');

const maxHeight = (_highest: number = 0): number => {
  if (_highest > 70) {
    return 100;
  } else if (_highest > 40) {
    return 70;
  } else if (_highest > 15) {
    return 40;
  } else {
    return 30;
  }
};

const generateGraph = (labels: string[], data: (number | undefined)[], maxNum: number): (JSX.Element | undefined)[] => {

  const inOrder = data.sort();
  const highest = inOrder[inOrder.length - 1];
  const width = 100 / data.length + 2;

  return labels.map((label, index) => {
    if (index < maxNum) {
      return (
        <div style={{font: '1rem', width: width + '%'}} key={+index}>
          <div>{label}</div>
          <div style={{height: maxHeight(highest) + 'px', display: 'flex', alignItems: 'end'}}>
            <div style={{height: data[index] + 'px', width: '100%', background: 'black'}}></div>
          </div>
          <div>{data[index]}%</div>
        </div>
      );
    } else {
      return <></>;
    }
  });
};

export const RainGraph: FunctionComponent<{forecast: HourlyForecast[]}> = ({forecast}) => {

  const reduceProbability = (_forecast: HourlyForecast[]): number[] => {
    return _forecast.map((f) => f.RainProbability)
  };

  const reduceLabels = (_forecast: HourlyForecast[]): string [] => {
    return _forecast.map((f) => formatTime(f.DateTime));
  };

  const maxNum = 6;
  const labels = reduceLabels(forecast);
  const data = reduceProbability(forecast);
  const graph = generateGraph(labels, data, maxNum);
  return (<Card label={'Rain'} classList={'center'}>{graph}</Card>);
};

export const AirAndPollenGraph: FunctionComponent<{forecast5dayInfo: DForecast}> = ({forecast5dayInfo}) => {
  const maxNum = 5;

  const data = forecast5dayInfo.DailyForecasts.map(df => (
    df.AirAndPollen.find(ap => ap.Name === 'AirQuality')?.CategoryValue
  ));

  const labels = forecast5dayInfo.DailyForecasts.map(df => formatTime(df.Date));
  const graph = generateGraph(labels, data, maxNum);

  return (<Card label={'AirQuality'} classList={'center'}>{graph}</Card>);
};

export const HourlyRating: FunctionComponent<{hourlyForecast: HourlyForecast[]}> = ({hourlyForecast}) => {
  const data = hourlyForecast.map(h => ratingForHour(h).rating);
  const labels = hourlyForecast.map(h => formatTime(h.DateTime));
  const graph = generateGraph(labels, data, 5);
  return (<Card label={'Hourly Rating'} classList={'center'}>{graph}</Card>);
};
