import { format } from 'date-fns';

/**
 * accuweather helper
 * ex weather {"Value":79,"Unit":"F","UnitType":18}
 * @param weather
 * @param target
 * @param unit
 */
export const getValue = (weather: any, target: string, unit?: string): string => {
  if (unit) {
    return weather?.[target]?.[unit]?.Value + ' ' + weather?.[target]?.[unit].Unit;
  } else {
    return weather?.[target]?.Value + ' ' + weather?.[target]?.Unit;
  }
};

export const getTemp = (weather: any, target: string, unit?: string): string => {
  if (unit) {
    return weather?.[target]?.[unit]?.Value + '°' + weather?.[target]?.[unit].Unit;
  } else {
    return weather?.[target]?.Value + '°' + weather?.[target]?.Unit;
  }
};

/**
 *
 * @param date
 */
export const getDate = (date: string): string => {
  return format(new Date(date), 'EEEE, LLLL do');
}

export const getTime = (date: string): string => {
  return format(new Date(date), 'hh:mm a');
}
