import React from 'react';
import './App.css';
import { Main } from './components/Main';

function App(): JSX.Element {
  return (
    <main>
      <Main/>
    </main>
  );
}

export default App;




