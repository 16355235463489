import { FunctionComponent } from 'react';

export const Rating: FunctionComponent<{rating: number}> = ({rating}): JSX.Element => {
  return (
    <>
      <div className="rating-title">Rating</div>
      <div className="rating-number">{rating}/10</div>
    </>
  )
}
